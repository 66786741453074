<template>
  <div>
    <section class="section-our-business">
      <div class="section-content mx-auto">
        <div class="section-our-business-info">
          <h1 class="section-header header__headline">Bring your business to life with Roxie</h1>
          <v-btn
            depressed
            class="mt-4 mt-md-10 px-6 px-sm-12 py-4 header__btn"
            :to="{name: 'Register'}"
          >
            <img class="mr-2" width="24" height="24" :src="require('@/assets/img/icons/ic-arrow-right-white.svg')"
                 alt="">
            Start selling
          </v-btn>
        </div>
      </div>
      <div class="section-our-business-img d-inline-block">
        <img :src="require('@/assets/img/landings/how-it-works/business.png')" alt="">
      </div>
    </section>

    <section class="section-business-inst">
      <h2 class="section-header text-center header__title">Build your <span class="part">business with ease</span>:</h2>
      <div class="section-content">
        <div class="section-business-inst-info mx-auto">
          <div class="section-business-inst-row d-flex align-start">
            <div class="section-business-inst-img">
              <img :src="require('@/assets/img/landings/how-it-works/woman.png')" alt="">
              <div class="section-business-install-roxie d-flex flex-column align-center flama-bold--font primary--text white">
                <img :src="require('@/assets/img/landings/how-it-works/roxie.svg')" alt="">
                <div class="d-flex justify-center align-center white">
                  <img :src="require('@/assets/img/icons/ic-arrow-right-pink.svg')" alt="">
                </div>
                Install Roxie
              </div>
            </div>
            <div>
              <h3 class="mb-2 marker-mark--font font-weight-regular light-blue--text install-header">Install</h3>
              <h4 class="mb-8 cochin-lt--font font-weight-regular headline-h4 headline-h4--first">The Roxie App</h4>
              <p class="mb-0 cochin-lt--font paragraph-text">Sign up for a Roxie account and begin the simple and quick installation process! Integration with Shopify has never been easier; click “Sign Up” above and begin browsing!</p>
            </div>
          </div>

          <div class="section-business-inst-row d-flex align-start">
            <div>
              <h3 class="mb-4 marker-mark--font font-weight-regular light-blue--text cta-header-0">Add & Browse</h3>
              <h4 class="mb-12 cochin-lt--font font-weight-regular headline-h4 headline-h4--second">Products to your e-store</h4>
              <p class="mb-0 cochin-lt--font">Browse through an ever-evolving, extensive collection of top-notch, intricately crafted jewelry.</p>
              <p class="mb-0 cochin-lt--font"><br>Personalize your jewelry to your liking; toggle between various metal finishes, diamond and color stone accessories, and add personal messages in the form of engravings.</p>
            </div>
            <div class="section-business-inst-img">
              <img :src="require('@/assets/img/landings/how-it-works/products.png')" alt="">
            </div>
          </div>

          <div class="section-business-inst-row d-flex align-start">
            <div class="section-business-inst-img">
              <img :src="require('@/assets/img/landings/how-it-works/pricing.png')" alt="">
            </div>
            <div>
              <h3 class="mb-4 marker-mark--font font-weight-regular light-blue--text headline-bg-square cta-header-1">Set</h3>
              <h4 class="mb-12 cochin-lt--font font-weight-regular headline-h4 ">Your desired profit</h4>
              <p class="mb-0 cochin-lt--font">Let our helpful profit calculators guide you. Set custom margins for your products and variants, allowing for detailed pricing and margin adjustment.</p>
            </div>
          </div>

          <div class="section-business-inst-row d-flex align-start">
            <div>
              <h3 class="mb-4 marker-mark--font font-weight-regular light-blue--text headline-bg-square cta-header-2">Start</h3>
              <h4 class="mb-12 cochin-lt--font font-weight-regula headline-h4 headline-h4--forth">Selling quality products on your e-store</h4>
              <p class="mb-0 cochin-lt--font">Begin selling fine jewelry on your e-store!</p>
              <p class="mb-0 cochin-lt--font">Leave the tedious inventory, shipping and order management to us. Simply select and customize jewelry from our ever-growing, extensive catalogue and start earning by selling on your e-store. It’s that easy!</p>
            </div>
            <div class="section-business-inst-img">
              <img :src="require('@/assets/img/landings/how-it-works/widget.png')" alt="">
              <div class="marker-mark--font font-weight-regular light-blue--text marker-last headline-bg-square cta-header-3">
                <h3 class="mb-4 marker-mark--font font-weight-regular light-blue--text cta-header-earning">Keep earning!</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-choose-design text-center">
      <h2 class="section-header mb-12 mb-sm-6 header__title">Choose a design <br> that speaks to you.</h2>
      <div class="section-content">
        <div class="section-choose-design-item mb-12">
          <img :src="require('@/assets/img/landings/how-it-works/horizontal-design.png')" alt="">
        </div>
        <div class="section-choose-design-item ">
          <div class="section-choose-design-label d-flex align-center justify-center">
            <span class="marker-mark--font white--text">The Minimalist</span>
          </div>
          <img :src="require('@/assets/img/landings/how-it-works/vertical-design.png')" alt="">
        </div>
      </div>
      <div class="section-choose-design-result">
        <div class="middle-bg"></div>
        <img class="images__block" :src="require('@/assets/img/landings/how-it-works/hands.png')" alt="">
        <img class="images__block images__block--2" :src="require('@/assets/img/landings/how-it-works/girls2.png')" alt="">
        <div class="line line--top-left"></div>
        <div class="line line--top-right"></div>
        <div class="line line--bottom-left"></div>
        <div class="line line--bottom-right"></div>
      </div>
    </section>

    <section class="section-money-widget">
      <div class="landing-money-widget mx-auto">
        <h2 class="section-header text-center header-h2-title">Start counting your money. <br>
          From a one-person business to a global e-commerce store.</h2>

        <p class="section-sub-header px-4 dm-serif-display--font text-center mb-7">Unsure of your expected performance? Compare yourself to industry benchmarks.</p>

        <div class="landing-money-widget-row mb-10 d-flex flex-row flex-wrap justify-center money-widget-presets-row">
          <v-btn
            v-for="preset in presets"
            :key="preset.value"
            outlined
            class="mx-2 mx-md-6 mb-3"
            @click="setCalculatorPreset(preset.value)"
          >
            <span class="dm-serif-display--font text-none">{{ preset.label }}</span>
          </v-btn>
        </div>

        <div class="landing-money-widget-row mb-13 d-flex align-center">
          <p class="mr-auto dm-serif-display--font font-weight-regular">Traffic</p>
          <div class="landing-money-widget-range d-flex align-center">
            <span>1K</span>
            <v-slider
              v-model="traffic"
              min="0"
              max="100"
              step="1"
              thumb-label="always"
              hide-details
            >
              <template v-slot:thumb-label="{ value }">
                {{ value }}K
              </template>
            </v-slider>
            <span>100K</span>
          </div>
        </div>

        <div class="landing-money-widget-row mb-13 d-flex align-center">
          <p class="mr-auto dm-serif-display--font font-weight-regular">Conversion (%)</p>
          <div class="landing-money-widget-range d-flex align-center">
            <span>0%</span>
            <v-slider
              v-model="conversion"
              min="0"
              max="5"
              step="0.5"
              thumb-label="always"
              hide-details
            >
              <template v-slot:thumb-label="{ value }">
                {{ value }}%
              </template>
            </v-slider>
            <span>5%</span>
          </div>
        </div>

        <div class="landing-money-widget-row mb-13 money-widget-average-row retail-price d-flex align-center">
          <p class="dm-serif-display--font font-weight-regular">Your Average Order Value</p>
          <div>
            <input
              v-model="averageOrderValue"
              @change="validateNumber($event, 'averageOrderValue')"
              @keyup="validateNumber($event, 'averageOrderValue')"
              class="flama--font"
            >
            <span><strong class="d-inline-block font-weight-regular">$</strong><b
              class="font-weight-regular">{{ averageOrderValue }}</b></span>
          </div>
        </div>

        <div class="landing-money-widget-row mb-15 d-flex align-center">
          <p class="mr-auto dm-serif-display--font font-weight-regular">Set Margin (%)</p>
          <div class="landing-money-widget-range d-flex align-center">
            <span>0%</span>
            <v-slider
              v-model="margin"
              min="0"
              max="100"
              thumb-label="always"
              hide-details
            >
              <template v-slot:thumb-label="{ value }">
                {{ value }}%
              </template>
            </v-slider>
            <span>100%</span>
          </div>
        </div>

        <div class="landing-money-widget-row d-flex align-center primary">
          <p class="mr-auto white--text dm-serif-display--font font-weight-regular">Profit based on your estimation:</p>
          <div class="white text-right">${{ formatPrice(profit) }}</div>
        </div>
      </div>
    </section>

    <FAQ></FAQ>
  </div>
</template>

<script>
import FAQ from "@/components/landings/FAQ";

export default {
  name: 'HowItWorksLanding',
  components: {
    FAQ,
  },
  data: () => ({
    traffic: 10,
    conversion: 1,
    averageOrderValue: 300,
    margin: 50,
    presets: [
      {value: 'beginner', label: "Beginner"},
      {value: 'experienced', label: "Experienced"},
      {value: 'advanced', label: "Advanced"},
      {value: 'expert', label: "Expert"},
    ]
  }),
  computed: {
    profit() {
      const profit = (this.traffic * 1000 / 100 * this.conversion * this.averageOrderValue / 100 * this.margin).toFixed(2);
      return isNaN(profit) ? 0 : profit;
    }
  },
  methods: {
    formatPrice(price) {
      return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    validateNumber($event, key) {
      if ($event.target.value.toString().charAt(0) === '0') {
        $event.target.value = $event.target.value.substring(1, 7);
      }

      if (/[^0-9]/g.test($event.target.value)) {
        $event.target.value = $event.target.value.replace(/[^0-9]/g, '').substring(0, 7);
      }

      $event.target.value = $event.target.value.toString().substring(0, 7);
      this[key] = $event.target.value;
    },
    setCalculatorPreset(preset = '') {
      switch (preset) {
        case 'beginner':
          this.traffic = 1;
          this.conversion = 0.5;
          this.averageOrderValue = 50;
          this.margin = 30;
          break;

        case 'experienced':
          this.traffic = 10;
          this.conversion = 1;
          this.averageOrderValue = 200;
          this.margin = 40;
          break;

        case 'advanced':
          this.traffic = 25;
          this.conversion = 2;
          this.averageOrderValue = 300;
          this.margin = 45;
          break;

        case 'expert':
          this.traffic = 50;
          this.conversion = 3;
          this.averageOrderValue = 500;
          this.margin = 50;
          break;
      }
    }
  }
}
</script>
